export default class NavbarEnum {
    static BODY = 'body'
    static NAVBAR = '[data-element="navbar"]'
    static LOGO = '[data-element="navbar-logo"]'
    static ECOMODE = '[data-id="eco-bar"]'

    static BURGER_MENU_TRIGGER = '[data-trigger="burger-menu-toggle"]'
    static BURGER_MENU_MORE = '[data-trigger="burger-menu-more"]'
    static BURGER_MENU_PREV = '[data-trigger="burger-menu-prev"]'
    static BURGER_MENU_CLOSE = '[data-trigger="burger-menu-close"]'
    static BURGER_SUBMENU = '[data-element="burger-submenu"]'

    static LANG_SWICTH_TRIGGER = "[data-trigger='lang-switcher']"
    static LANG_SWICTH_ELEMENT = "[data-element='lang-switcher-list']"

    static SEARCH_TRIGGER = "[data-hc-modal][href='#modal-wp-search']"
    static SEARCH_MODAL_INPUT = "#modal-wp-search input[name='s']"
}
