import NavbarEnum from '../enumerators/navbar.enum'

export default class NavbarComponent {
    constructor() {
        NavbarComponent.sticky()
        NavbarComponent.burger()
        NavbarComponent.lang()
        NavbarComponent.navMenuAccelerator()
        NavbarComponent.submenu()
        NavbarComponent.searchFocus()
    }

    static sticky() {
        let lastScrollTop = 0
        let stateOnTop = true

        const offset = 100 // Navbar default height
        $(window).on('load scroll', function () {
            const offsetOnScroll = 100 // Navbar height on scroll
            const navbar = $(NavbarEnum.NAVBAR)
            const scrollTop = $(this).scrollTop()
            let hasBanner = false

            // Detect if header is on banner/video mode
            if ($('body.has-banner').length > 0) {
                hasBanner = true
            }

            // Hidden on scroll comportment
            if (scrollTop >= lastScrollTop && scrollTop > navbar.outerHeight()) {
                $(NavbarEnum.BODY).addClass('header-is-hidden')
            } else {
                $(NavbarEnum.BODY).removeClass('header-is-hidden')
            }

            if ($(NavbarEnum.BODY).hasClass('menu-open')) {
                $(NavbarEnum.BODY).removeClass('header-is-hidden')
            }

            lastScrollTop = scrollTop

            // On top/on scroll switch
            if (scrollTop > offset && stateOnTop === true) {
                stateOnTop = false
                navbar.removeClass('navbar--ontop')
                navbar.addClass('navbar--onscroll')
                $(NavbarEnum.BODY).removeClass('hp-header-animated')

                if (hasBanner === true) {
                    NavbarComponent.changeLogoColor('classic')
                }
            } else if (scrollTop < offsetOnScroll && stateOnTop === false) {
                stateOnTop = true
                navbar.removeClass('navbar--onscroll')
                navbar.addClass('navbar--ontop')

                if (hasBanner === true) {
                    NavbarComponent.changeLogoColor('banner')
                }
            }
        })
    }

    static changeLogoColor(color) {
        const logo = $(NavbarEnum.LOGO)
        let logoBanner
        let logoClassic

        logoBanner = IRISCollectionCustomer.imageLogoBanner
        logoClassic = IRISCollectionCustomer.imageLogo

        if (color === 'banner') {
            logo.attr('src', logoBanner)
        } else if (color === 'classic') {
            logo.attr('src', logoClassic)
        }
    }

    static burger() {
        //Open/Close menu
        $(NavbarEnum.BURGER_MENU_TRIGGER).on('click', () => {
            $(NavbarEnum.BODY).toggleClass('menu-open')
            //Hide ecomode
            $(NavbarEnum.ECOMODE).addClass('eco-bar--hidden')
        })
    }

    static submenu() {
        const menuItemLink = $('[data-trigger="burger-submenu-toggle"]')
        const subMenuItem = $('[data-element="burger-submenu"]')
        const subMenuWrapper = $('[data-element="burger-submenus-wrapper"]')

        // Open submenu on mobile
        if (window.matchMedia('(max-width:1000px)').matches) {
            menuItemLink.removeClass('submenu-open')
            subMenuItem.removeClass('active')
        }

        // Open/close submenu
        menuItemLink.on('click', function (e) {
            const targetItem = $(this).data('element-menu-item')
            const submenuKey = $(`[data-submenu-key="${targetItem}"`)

            if (window.matchMedia('(min-width:1001px)').matches) {
                // second click (already open)
                if ($(this).hasClass('submenu-open')) {
                    return true
                }
            }

            // first click (open submenu)
            menuItemLink.removeClass('submenu-open')
            $(this).toggleClass('submenu-open')

            subMenuItem.removeClass('active')
            submenuKey.toggleClass('active')

            if (window.matchMedia('(max-width:1000px)').matches) {
                subMenuWrapper.removeClass('wrapper-submenu-open')

                if (subMenuWrapper.find('.active').length > 0) {
                    subMenuWrapper.addClass('wrapper-submenu-open')
                }

                $(NavbarEnum.BODY).addClass('submenu-open')
            }

            return false
        })

        $(NavbarEnum.BURGER_MENU_PREV).on('click', (e) => {
            e.preventDefault()
            $(NavbarEnum.BURGER_SUBMENU).removeClass('submenu-open')
            $(NavbarEnum.BODY).removeClass('submenu-open')
            subMenuWrapper.removeClass('wrapper-submenu-open')
            subMenuItem.removeClass('active')
            menuItemLink.removeClass('submenu-open')
        })

        $(NavbarEnum.BURGER_MENU_CLOSE).on('click', (e) => {
            e.preventDefault()
            $(NavbarEnum.BURGER_SUBMENU).removeClass('submenu-open')
            subMenuWrapper.removeClass('wrapper-submenu-open')
            $(NavbarEnum.BODY).removeClass('submenu-open')
            $(NavbarEnum.BODY).removeClass('menu-open')
            subMenuItem.removeClass('active')
            menuItemLink.removeClass('submenu-open')
        })
    }

    static navMenuAccelerator() {
        const navMenuItem = $('[data-trigger="menu-item"]')
        const subMenuItemTrigger = $('[data-element="burger-submenu"]')
        const maxiMenuTrigger = $('[data-trigger="burger-submenu-toggle"]')

        navMenuItem.on('click', function (e) {
            if ($(this).hasClass('has-submenu')) {
                e.preventDefault()
                $(NavbarEnum.BODY).toggleClass('menu-open')
                const target = $(this).data('target')

                const subMenuItem = $(`[data-submenu-key="${target}"`)
                const maxiMenuItem = $(`[data-element-menu-item="${target}"`)

                maxiMenuTrigger.removeClass('submenu-open')
                subMenuItemTrigger.removeClass('active')
                subMenuItem.addClass('active')
                maxiMenuItem.addClass('submenu-open')
            }
        })
    }

    static lang() {
        $(NavbarEnum.LANG_SWICTH_ELEMENT).hide()

        $(NavbarEnum.LANG_SWICTH_TRIGGER).on('click', () => {
            $(NavbarEnum.LANG_SWICTH_ELEMENT).toggleClass('open')
            $(NavbarEnum.LANG_SWICTH_ELEMENT).hasClass('open')
                ? $(NavbarEnum.LANG_SWICTH_ELEMENT).fadeIn(250)
                : $(NavbarEnum.LANG_SWICTH_ELEMENT).fadeOut(250)

            //Hide list lang on outside click
            if ($(NavbarEnum.LANG_SWICTH_ELEMENT).hasClass('open')) {
                $(window).click((e) => {
                    if (
                        $(e.target).attr('data-trigger') !== 'lang-switcher' &&
                        e.target.className !== 'current__lang'
                    ) {
                        $(NavbarEnum.LANG_SWICTH_ELEMENT).fadeOut(250)
                    }
                })
            }
        })
    }

    static searchFocus() {
        if ($(NavbarEnum.SEARCH_MODAL_INPUT).length > 0) {
            $(NavbarEnum.SEARCH_TRIGGER).on('click', () => {
                document.addEventListener('show.hc.modal', () => {
                    $(NavbarEnum.SEARCH_MODAL_INPUT).focus()
                })
            })
        }
    }
}
